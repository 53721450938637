import React, { Component } from "react";
import Breadcrumb from "../util/Breadcrumb";
import {AddButton, DeleteButton, ReloadButton} from "../util/ButtonUtil";
import ErrorBanner from "../util/ErrorBanner";
import {observable} from "mobx";
import { wrap } from "../util/Utils";
import Server from "../util/Server";

class System extends Component {
    store = observable ({
        error: null,
        loading: false
    });

    render() {
        return (
            <div className={"Standalone"}>
                <ErrorBanner error={this.store.error} />

                <h1>Data</h1>

                <ul>
                    <li>
                        <a href={"#/data/geos"}>Geos</a>
                    </li>
                    <li>
                        <a href={"#/data/jobPostings"}>JobPostings</a>
                    </li>
                    <li>
                        <a href={"#/data/employers"}>Employers</a>
                    </li>
                </ul>
                <h1>System</h1>

                <table>
                    <tr>
                        <td>
                            <DeleteButton onClick={() => {
                                this.gql("mutation { res: deleteJobPostings }")
                            }}/>
                        </td>
                        <td>
                            Delete all job postings.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DeleteButton onClick={() => {
                                this.gql("mutation { res: deleteEmployers }")
                            }}/>
                        </td>
                        <td>
                            Delete all employers.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ReloadButton onClick={() => {
                                this.gql("mutation { res: reindex }");
                            }}/>
                        </td>
                        <td>
                            Rebuild search index.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <AddButton onClick={() => {
                                this.gql("mutation { res: createFakeJobPostings (count: 100) }");
                            }}/>
                        </td>
                        <td>
                            Create fake job postings.
                        </td>
                    </tr>
                </table>
            </div>
        );
    }

    async gql(query, variables) {
        try {
            this.store.loading = true;
            this.store.results = null;
            this.store.error = null;
            const results = await Server.gql(query, variables);
            this.store.results = results;
            this.store.result = results.data?.res;
            if (this.store.results.errors.length > 0) {
                this.store.error = this.store.results.errors;
            } else {
                const {snackbarStore} = this.props;
                const message = this.store.result ? JSON.stringify (this.store.result) : 'done';
                console.log (JSON.stringify(this.store.result, null, 2));
                snackbarStore.show (message);
            }
        }
        catch (e) {
            console.log (e);
            this.store.error = e;
        }
        finally {
            this.store.loading = false;
        }
    }
}

export default wrap (System);

// EOF