
class EmployerUtil {
    findEmployersGql =  `query ($req: EmployerFindRequest!) { 
            findEmployers (req: $req) { 
                total 
                skip
                limit            
                results { 
                    id
                    name
                    state
                    sein
                    geoKey
                    primaryContact { name phone email } 
                }
            }
        }`;

    linkUrl (employerId) {
        return `#/data/employer/${employerId}`;
    }
}

export default new EmployerUtil ();

// EOF