import React, { Component } from "react";
import "./css/Header.css";
import { wrap } from "../util/Utils";
import logo from "./Candyland.png";
import headerStore from "../util/HeaderStore";
import {StateNames} from "@mirawision/usa-map-react";
import flag from "./flag.jpg";

class Header extends Component {
    render() {
        return (
            <div className={"Header"}>
                <div className="HeaderLogo" onClick={() => window.location.href = "/#"}>
                    {this.getHeader()}
                </div>
                <div className={"SystemButton"}>
                    <i className="far fa-cog" onClick={() => window.location.hash = "/system"}/>
                </div>
            </div>
        );
    }

    getHeader() {
        const {state} = headerStore;
        if (state) {
            if (state === "CL") {
                return <img src={logo} alt={"logo"}/>
            } else {
                return (
                    <div className={"HeaderText"}>
                        {StateNames[state]} Job Center
                    </div>
                );
            }
        } else {
            return (
                <div className={"HeaderText"}>
                    <img height={64} src={flag} />
                    &nbsp;
                    Abide Job Hub
                </div>
            );
        }
    }
}

export default wrap(Header);

// EOF