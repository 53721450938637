import React from 'react';
import {encodeUrl, formatDate, objGet, wrap, toBullets} from "../../util/Utils";
import PropertyTable from "../../util/PropertyTable";
import ID from "../../util/ID";
import ThingDetail from "../thing/ThingDetail";
import {AddButton, AuditButton, DeleteButton, EditButton, IconButton, ShowButton} from "../../util/ButtonUtil";
import {batchLink, employerLink, geoLink} from "../thing/ThingUtil";
import {JobState} from "../../util/enum/EnumSlug";

/**
 *
 */

class ShowJobPosting extends ThingDetail {
    constructor () {
        super ({
            type: "JobPosting"
        });
    }

    get query () {
        return `
        query ($id: String!) {
            res: jobPostingById (id: $id) {
                id
                jobKey
                title
                description
                location { city state } 
                employer
                toApply { url instructions contact } 
                requirements
                compensation
                eeoStatement
                subsidiary 
                onetCode 
                educationType 
                employmentType
                posted
            } 
        }`;
    }

    actions (job) {
        return (
            <div>
                {/*<AuditButton onClick={()=> {*/}
                {/*    window.location.hash = `/data/job/${job.id}/audit`;*/}
                {/*}} />*/}
            </div>
        );
    }

    /**
     *
     * @param jobId
     */

    doRender (job) {
        if (! job) {
            return (
                <span>
                    JobPosting not found.
                </span>
            );
        }

        const o = {
            id: <ID snackbar={true} value={job.id} />,
            employer: job.employer,
            jobKey: job.jobKey,
            title: job.title,
            description: job.description,
            "location.statae": job.location.state,
            "location.city": job.location.city,
            "location.additional": job.location.additional || "-",
            "toApply.url": job.toApply.url,
            "toApply.instructions" : job.toApply.instructions,
            "toApply.contact": job.toApply.contact || '-',
            requirements: toBullets (job.requirements),
            compensation: job.compensation || "-",
            eeoStatement: job.eeoStatement || "-",
            subsidiary: job.subsidiary || '-',
            onetCode: job.onetCode || '-',
            educationType: job.educationType || '-',
            employmentType: job.employmentType || '-',
            posted: job.posted,
        };

        formatDate (o, [ "posted" ]);

        return (
            <div>
                <PropertyTable value={o} size={"small"} />
                <br/>
                {this.actions (job)}
            </div>
        )
    }
}

export default wrap (ShowJobPosting);

// EOF