import "./css/Home.css";
import React, {Component} from "react";
import {observable, toJS} from "mobx";
import {PreJson, wrap, fixedPoint, encodeUrl, withCommas, doGql} from "../util/Utils";
import gingerbreadHouse from "./gingerbread.gif";
import {StateAbbreviations, USAMap} from '@mirawision/usa-map-react';
import _ from "lodash";

const NONE = "#ededed";

const COLORS = [
    "#d9ed92ff",
    "#b5e48cff",
    "#99d98cff",
    "#76c893ff",
    "#52b69aff",
    "#34a0a4ff",
    "#168aadff",
    "#1a759fff",
    "#1e6091ff",
    "#184e77ff"
];

class Home extends Component {
    store = observable ({
        loading: false,
        error: null,
        results: null,
        result: null
    });

    async componentDidMount() {
        const result = await doGql (this);
    }

    go (state) {
        window.location.hash = `#/${state}/search`;
    }

    render () {
        const { result} = this.store;

        const defaults = {
            onClick: (state) => null,
            fill: NONE
        };

        const mapSettings = {
            onClick: (e) => this.go (e),
            fill: NONE
        };

        console.log (toJS (result));

        if (result?.jobsByState) {
            const geos = { };
            let max = 0;
            _.forEach (result.jobsByState, (el) => {
                geos[el.state] = el.count;
                if (el.count > max) {
                    max = el.count;
                }
            });
            _.forEach (geos, (count, geo) => {
                const which = Math.floor (count / max * (COLORS.length - 1));
                mapSettings[geo] = {
                    fill: COLORS[which]
                };
            });
        }

        return (
            <div className={"MapWrapper"}>
                <div>
                    {this.renderStats (this.store.result)}
                </div>
                <div className={"Map"}>
                    <USAMap defaultState={defaults} customStates={mapSettings}/>
                </div>
                <div>
                    <img width={72} src={gingerbreadHouse} onClick={() => this.go ("CL")}/>
                    Candyland (CL)
                </div>
            </div>
        );
    }

    get query () {
        return `query { 
            res: getStats {
                jobPostings geos employers
                jobsByState {
                    state 
                    count
                }  
            } 
        }`;
    }
    get variables () {
        return {};
    }

    renderStats (stats) {
        if (!stats) {
            return null;
        }
        return (
            <div>
                <Module label={"Employers"} value={stats.employers} hash={"/data/employers"} />
                <Module label={"JobPostings"} value={stats.jobPostings} hash={"/data/jobPostings"}/>
                <Module label={"Geos"} value={stats.geos} hash={"/data/geos"}/>
            </div>
        );
    }
}

const Module = ({ label, value, hash }) => {
    return (
        <div className={"Module"} onClick={() => window.location.hash = hash}>
            <div className={"Module-Value"}>
                {withCommas (value)}
            </div>
            <div className={"Module-Label"}>
                {label}
            </div>
        </div>
    );
}

export default wrap(Home);

// EOF