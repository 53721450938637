import React from "react";
import {createHashRouter} from "react-router-dom";
import Home from "./Home";
import FindJobPostings from "../data/jobPosting/FindJobPostings";
import ShowJobPosting from "../data/jobPosting/ShowJobPosting";
import System from "../system/System";
import ShowGeo from "../data/geo/ShowGeo";
import FindGeos from "../data/geo/FindGeos";
import Search from "../search/Search";
import FindEmployers from "../data/employer/FindEmployers";
import ShowEmployer from "../data/employer/ShowEmployer";

/**
 *
 * @type {Router}
 */

const ROUTES = createHashRouter ([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/:id/search",
        element: <Search />
    },
    {
        path: "/data/jobPostings",
        element: <FindJobPostings />
    },
    {
        path: "/data/jobPosting/:id",
        element: <ShowJobPosting />
    },
    {
        path: "/system",
        element: <System/>
    },
    { path: "/data/geos", element: <FindGeos /> },
    { path: "/data/geo/:id", element: <ShowGeo /> },
    { path: "/data/employers", element: <FindEmployers /> },
    { path: "/data/employer/:id", element: <ShowEmployer /> },

]);

export default ROUTES;

// EOF