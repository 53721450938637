import React from 'react';
import {encodeUrl, formatDate, objGet, PreJson, wrap} from "../../util/Utils";
import PropertyTable from "../../util/PropertyTable";
import ID from "../../util/ID";
import ThingDetail from "../thing/ThingDetail";
import Breadcrumb from "../../util/Breadcrumb";
import {DeleteButton, EditButton} from "../../util/ButtonUtil";

/**
 *
 */

class DataEmployer extends ThingDetail {
    constructor () {
        super ({
            type: "Employer"
        });
    }

    get query () {
        return `
        query ($id: String!) {
            res: employerById (id: $id) {
                id
                state
                sein
                name
                apiKey
                geoKey
                primaryContact { name email phone } 
            } 
        }`;
    }

    actions (employer) {
        return null;
    }

    /**
     *
     * @param employerId
     */

    doRender (employer) {
        if (! employer) {
            return (
                <span>
                    Employer not found.
                </span>
            );
        }

        const o = {
            id: <ID snackbar={true} value={employer.id} />,
            name: employer.name,
            sein: employer.sein,
            state: employer.state,
            geoKey: employer.geoKey,
            primaryContact: <PreJson json={employer.primaryContact} />,
            apiKey: <ID value={employer.apiKey} snackbar={true}/>

        };

        return (
            <div>
                <PropertyTable value={o} size={"small"} />
                <br/>
                {this.actions (employer)}
            </div>
        )
    }
}

export default wrap (DataEmployer);

// EOF