import './App.css';
import '../global/css/Global.css';
import Routes from "./Routes";
import {RouterProvider} from "react-router";
import {Component} from "react";
import {observer} from "mobx-react";
import Header from "../global/Header";
import Footer from "../global/Footer";


class App extends Component {
    render() {
        return (
            <div className={"OuterWrapper"}>
                <Header/>
                <div className={"InnerWrapper"}>
                    <RouterProvider router={Routes} onChange={() => console.log ("sd")}/>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default observer (App);

// EOF