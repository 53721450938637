import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './home/App';
import InfoDialog, {infoDialogStore} from "./util/InfoDialog";
import Server from "./util/Server";
import {Provider} from "mobx-react";
import SnackbarPopop, {snackbarStore} from "./util/snackbarStore";
import Environment from "./util/Environment";
import EnumManager from "./util/enum/EnumManager";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider
            snackbarStore={snackbarStore}
            environment={Environment}
            infoDialogStore={infoDialogStore}
            Server={Server}
            EnumManager={EnumManager}
        >
            <App />
            <InfoDialog/>
            <SnackbarPopop />
        </Provider>
    </React.StrictMode>
);

Environment.load ();

// EOF