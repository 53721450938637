import React from 'react';
import {wrap, getParams} from "../../util/Utils";
import ID from "../../util/ID";
import DataTable from "../DataTable";
import Button from "@mui/material/Button";
import ThingDetails from "../thing/ThingDetails";
import {action} from "mobx";
import {Drawer} from "@mui/material";
import TextField from "@mui/material/TextField";
import JobUtil from "./JobPostingUtil";
import Breadcrumb from "../../util/Breadcrumb";
import {AddButton, ReloadButton} from "../../util/ButtonUtil";
import {employerLink, geoLink, jobLink} from "../thing/ThingUtil";
import YesNo from "../../util/YesNo";

/**
 *
 */

class FindJobPostings extends ThingDetails {
    constructor () {
        super ({
            heading: "JobPostings",
            hasFilters: true,
            jobKey: "", // filter
            employer: "", // filter
            state: "", // filter
            title: "", // filter
            id: "" // filter
        });
    }

    get query () {
        return JobUtil.findJobsGql;
    }

    extendVariables (variables) {
        const { sort } = this.store;
        if (sort) {
            variables.sort = JSON.stringify (sort);
        }
        const filters = { };
        const {jobKey, employer, city, state, title, id } = this.store;
        if (jobKey) {
            filters.jobKey = jobKey;
        }
        if (title) {
            filters.title = title;
        }
        if (city) {
            filters.city = city;
        }
        if (state) {
            filters.state = state;
        }
        if (employer) {
            filters.employerId = employer.id;
        }
        if (id) {
            filters.id = id;
        }
        variables.filters = filters;
    }

    get headers () {
        return [
            this.sortHeader ("title", "Title"),
            this.sortHeader ("employer", "Employer"),
            this.sortHeader ("location.city", "City"),
            this.sortHeader ("location.state", "State"),
            this.sortHeader("_id", "ID")
        ];
    }

    xform (job) {
        return [
            job.title,
            job.employer,
            job.location.city,
            job.location.state,
            <ID short={true} value={job.id} />
        ];
    }

    doRender (fres) {
        return (
            <div>
                <DataTable
                    headers={this.headers}
                    xform={this.xform.bind (this)}
                    rows={fres.results}
                    skip={fres.skip}
                    onClick={job => window.location.href = `#/data/jobPosting/${job.id}`}
                />

                <br/>
                {this.showElapsed ()}
                <br/>

                <ReloadButton disabled={this.store.loading} onClick={() => this.doLoad ()} />
            </div>
        );
    }

    hasFilters () {
        const { jobKey, state, employer, title, id } = this.store;
        return Boolean (jobKey || state || employer || title || id);
    }

    clearFilters = action (() => {
        this.store.jobKey = "";
        this.store.state = "";
        this.store.employer = null;
        this.store.title = "";
        this.store.id = "";
        this.doLoad ();
    });

    renderFilters () {
        const { id, jobKey, employer, state, title, showDrawer } = this.store;
        const formProps = {margin: "dense", size: "small", fullWidth: true};

        return (
            <Drawer
                style={{ width: 250 }}
                anchor={"right"}
                open={showDrawer}
                onClose={this.toggleDrawer(false)}
            >
                <div className={"DataFilters"}>
                    <h2>Filters</h2>

                    <TextField
                        {...formProps}
                        value={id}
                        label={"ID"}
                        onChange={(e) => {
                            this.store.id = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <TextField
                        {...formProps}
                        value={employer}
                        label={"employer"}
                        onChange={(e) => {
                            this.store.employer = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <TextField
                        {...formProps}
                        value={jobKey}
                        label={"Job Key"}
                        onChange={(e) => {
                            this.store.jobKey = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <TextField
                        {...formProps}
                        value={state}
                        label={"State"}
                        onChange={(e) => {
                            this.store.state = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <TextField
                        {...formProps}
                        value={title}
                        label={"Title"}
                        onChange={(e) => {
                            this.store.title = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <br/>
                    <br/>
                    <Button size="small" variant="outlined" onClick={() => this.clearFilters ()}>
                        <i className="fas fa-trash" />
                        &nbsp;
                        Clear All
                    </Button>
                </div>
            </Drawer>
        )
    }
}

export default wrap (FindJobPostings);

// EOF