import {observable} from "mobx";

/**
 * Used to dynamically update the header based on context.
 */

class HeaderStore {
    store = observable ({
        state: null
    });

    set state (state) {
        this.store.state = state;
    }

    get state () {
        return this.store.state;
    }

    reset () {
        this.store.state = null;
    }
}

export default new HeaderStore ();

