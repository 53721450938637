import React, {Component} from "react";
import {observable} from "mobx";
import {PreJson, wrap, fixedPoint, encodeUrl, withCommas, doGql} from "../util/Utils";
import Validator from "../util/Validator";
import TextField from "@mui/material/TextField";
import {ClearButton, ReloadButton} from "../util/ButtonUtil";
import _ from "lodash";
import ErrorBanner from "../util/ErrorBanner";
import JobPosting from "../search/JobPosting";
import EnumPicker from "../util/enum/EnumPicker";
import Pagination from "@mui/material/Pagination";
import Server from "../util/Server";
import "./css/Search.css";
import {matchPath} from "react-router";
import headerStore from "../util/HeaderStore";

const fields = [
    {
        path: "keywords",
        name: "Keywords",
        required: false,
        validator: Validator.stringValidator
    },
    {
        path: "employer",
        name: "Employer",
        required: false,
        validator: Validator.stringValidator
    },
    {
        path: "title",
        name: "Title",
        required: false,
        validator: Validator.stringValidator
    },
    {
        path: "city",
        name: "City",
        required: false,
        validator: Validator.stringValidator
    },
    {
        path: "state",
        name: "State",
        required: false,
        validator: Validator.stringValidator
    }
];

/**
 * The home page.
 */

class Search extends Component {
    store = observable({
        error: null,
        errors: {},
        results: null,
        result: null,
        loading: false,
        limit: 20,
        skip: 0,
        page: 1,
        // fields
        keywords: "",
        city: "",
        state: "",
        employer: "",
        title: "",
        employmentType: null,
        educationType: null
    });

    constructor () {
        super ();
        Validator.addValidator(this, fields)
    }

    componentDidMount() {
        const match = matchPath({ path: "/:id/search" }, window.location.hash.substring (1));
        const state = match.params.id.toUpperCase();
        this.store.state = state;
        headerStore.state = state;
        this.search ();
    }

    componentWillUnmount() {
        headerStore.reset ();
    }

    render() {
        return (
            <div className={"HomeWrapper"}>
                {this.renderSidebar ()}
                {this.renderContent ()}
            </div>
        );
    }

    renderContent () {
        const { error, result } = this.store;
        return (
            <div className={"HomeContent"}>
                <ErrorBanner error={error} />
                {this.renderResult (result)}
            </div>
        );
    }

    renderPagination (results) {
        const { start, limit, total } = results;
        const pages = Math.ceil (total / limit);
        const page = Math.floor (total / start);

        return (
            <Pagination
                count={pages}
                showFirstButton
                showLastButton
                siblingcount={2}
                onChange={(e, v) => {
                    this.search (v - 1);
                }}
            />
        );
    }

    renderResult (result) {
        if (! result) {
            return "No results.";
        }
        return (
            <div>
                <div className={"SearchHeader"}>
                    <div>
                        Showing {result.results.length} of {result.total} job postings.
                    </div>
                    <br/>
                    <div className={"SearchPagination"}>
                        <div></div>
                        {this.renderPagination(result)}
                        <div></div>
                    </div>
                </div>
                <div>
                    {_.map (result.results, (el, i) => <JobPosting key={i} job={el} />)}
                </div>
                <div>

                </div>
            </div>
        );
        return;
    }

    textField (which, label, extra) {
        const { errors } = this.store;
        return (
            <TextField
                {...extra}
                label={label}
                value={this.store[which]}
                margin={"dense"}
                size={"small"}
                fullWidth
                onChange={(e) => {
                    this.store[which] = e.target.value;
                    this.search (0);
                }}
                helperText={errors[which]}
                error={Boolean (errors[which])}
                variant="outlined"
            />
        );
    }

    renderSidebar () {
        const { employmentType, educationType } = this.store;
        const formProps = {
            fullWidth: true,
            size: "small",
            marginTop: 18,
            border: "3px solid blue"
        };

        return (
            <div className={"HomeSidebar"}>
                <div className={"HomeFields"}>
                    {this.textField ("keywords", "Keywords")}
                    {this.textField ("employer", "Employer")}
                    {this.textField ("title", "Title")}
                    {this.textField ("city", "City")}
                    {this.textField ("state", "State", { disabled: true })}

                    &nbsp;
                    <EnumPicker
                        enumType={"EducationType"}
                        value={educationType}
                        onChange={value => {
                            this.store.educationType = value;
                            this.search ();
                        }}
                        formProps={formProps}
                    />
                    &nbsp;
                    <EnumPicker
                        enumType={"EmploymentType"}
                        value={employmentType}
                        onChange={value => {
                            this.store.employmentType = value;
                            this.search(0);
                        }}
                        formProps={formProps}
                    />

                    <div style={{textAlign: "center", padding: 10 }}>
                        <ClearButton label="Reset" onClick={() => {
                            _.each (["keywords", "employer", "title", "city", "state" ], (el, i) => {
                                this.store[el] = "";
                            });
                            this.store.educationType = null;
                            this.store.employmentType = null;
                            this.search (0);
                        }}/>
                        &nbsp;
                        <ReloadButton onClick={() => {
                            this.search ();
                        }}/>
                    </div>
                </div>
            </div>
        );
    }

    async search (page) {
        const query = this.query
        if (! _.isNumber (page)) {
            const res = this.store.results?.data?.res;
            if (res) {
                page = res.skip / res.limit;
            }
        }
        const variables = this.variables (page);
        try {
            this.store.loading = true;
            this.store.results = null;
            this.store.error = null;
            const results = await Server.gql (query, variables);
            this.store.results = results;
            this.store.result = results.data?.res;
            this.store.error = results.errors === [] ? null : results.errors;
            return this.store.result;
        }
        catch (e) {
            this.store.error = e;
        }
        finally {
            this.store.loading = false;
        }
        // doGql(this);
    }

    get query () {
        return `query ($req: SearchRequest!) {
            res: search (req: $req) {
                total 
                skip 
                limit
                results { 
                    id
                    title
                    employer
                    subsidiary
                    description
                    location { city state } 
                    posted
                    jobKey
                    onetCode
                    educationType
                    employmentType
                    posted
                } 
            }  
        }`;
    }

    variables (page) {
        const { keywords, employer, title, city, state, employmentType, educationType, limit } = this.store;
        return {
            req: {
                keywords: keywords || null,
                employer: employer || null,
                title: title || null,
                city: city || null,
                state: state || null,
                employmentType: employmentType || null,
                educationType: educationType || null,
                limit,
                skip: page * limit
            },
        }
    }
}

export default wrap(Search);

// EOF