import React from 'react';
import {wrap} from "../../util/Utils";
import ID from "../../util/ID";
import DataTable from "../DataTable";
import Button from "@mui/material/Button";
import ThingDetails from "../thing/ThingDetails";
import {action} from "mobx";
import {Drawer} from "@mui/material";
import TextField from "@mui/material/TextField";
import EmployerUtil from "./EmployerUtil";
import Breadcrumb from "../../util/Breadcrumb";
import {AddButton, ReloadButton} from "../../util/ButtonUtil";
import {employerLink} from "../thing/ThingUtil";
import {EmployerType} from "../../util/enum/EnumSlug";
import TableCell from "@mui/material/TableCell";
import EnumPicker from "../../util/enum/EnumPicker";

/**
 *
 */

class FindEmployers extends ThingDetails {
    constructor () {
        super ({
            heading: "Employers",
            hasFilters: true,
            name: "", //filter
            state: "", //filter
            geoKey: "", //filter
            id: "" // filter
        });
    }

    get query () {
        return EmployerUtil.findEmployersGql;
    }

    extendVariables (variables) {
        const { sort } = this.store;
        if (sort) {
            variables.sort = JSON.stringify (sort);
        }
        const filters = { };
        const { name, geoKey, state, id } = this.store;
        if (name) {
            filters.name = name;
        }
        if (geoKey) {
            filters.geoKey = geoKey;
        }
        if (state) {
            filters.state = state;
        }
        if (id) {
            filters.id = id;
        }
        variables.filters = filters;
    }

    get headers () {
        return [
            this.sortHeader ("name", "Name"),
            this.sortHeader ("geoKey", "Geo"),
            this.sortHeader ("sein", "State EIN"),
            this.sortHeader ("state", "State"),
            this.sortHeader("_id", "ID")
        ];
    }

    xform (employer) {
        return [
            employerLink (employer),
            employer.geoKey, 
            employer.sein,
            employer.state,
            <ID short={true} value={employer.id} />
        ];
    }

    doRender (fres) {
        return (
            <div>
                <DataTable
                    headers={this.headers}
                    xform={this.xform.bind (this)}
                    rows={fres.results}
                    skip={fres.skip}
                    onClick={employer => window.location.href = `#/data/employer/${employer.id}`}
                />

                <br/>
                {this.showElapsed ()}
                <br/>

                <ReloadButton disabled={this.store.loading} onClick={() => this.doLoad ()} />
            </div>
        );
    }

    hasFilters () {
        const { name, id, state } = this.store;
        return Boolean (name || id || state);
    }

    clearFilters = action (() => {
        this.store.name = "";
        this.store.state = "";
        this.store.geoKey = ""; 
        this.store.id = "";
        this.doLoad ();
    });

    renderFilters () {
        const { state, geoKey, name, id, showDrawer } = this.store;
        const formProps = {margin: "dense", size: "small", fullWidth: true};

        return (
            <Drawer
                style={{ width: 250 }}
                anchor={"right"}
                open={showDrawer}
                onClose={this.toggleDrawer(false)}
            >
                <div className={"DataFilters"}>
                    <h2>Filters</h2>

                    <EnumPicker
                        enumType={"EmployerState"}
                        value={state}
                        onChange={value => this.store.state = value}
                        formProps={formProps}
                    />
                    <TextField
                        {...formProps}
                        value={name}
                        label={"Name"}
                        onChange={(e) => {
                            this.store.name = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <TextField
                        {...formProps}
                        value={geoKey}
                        label={"GeoKey"}
                        onChange={(e) => {
                            this.store.geoKey = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <TextField
                        {...formProps}
                        value={id}
                        label={"ID"}
                        onChange={(e) => {
                            this.store.id = e.target.value;
                            this.doLoad();
                        }}
                    />
                    <br/>
                    <br/>
                    <Button size="small" variant="outlined" onClick={() => this.clearFilters ()}>
                        <i className="fas fa-trash" />
                        &nbsp;
                        Clear All
                    </Button>
                </div>
            </Drawer>
        )
    }
}

export default wrap (FindEmployers);

// EOF