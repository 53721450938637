import classnames from "classnames";
import "./EnumSlugs.css"

const EnumSlug = (type) => ({value}) => {
    return (
        <div className={classnames("EnumSlug", type, `${type}-${value}`)}>
            <i className="fas fa-circle"></i>
            &nbsp;
            {value}
        </div>
    );
};

const EmploymentType = EnumSlug ("EmploymentType");
const EducationType = EnumSlug ("EducationType");
const GeoType = EnumSlug ("GeoType");
const EmployerState = EnumSlug ("EmployerState");

export {
    EducationType,
    EmployerState,
    EmploymentType,
    EnumSlug,
    GeoType
};

// EOF