import React, { Component } from "react";
import "./css/JobPosting.css";
import {formatDate, shortenText} from "../util/Utils";
import moment from "moment/moment";
import {observable} from "mobx";
import {observer} from "mobx-react";

class JobPosting extends Component {
    store = observable ({
        expanded: false
    });

    render() {
        const { job } = this.props;

        return (
            <div className={"JobPosting"} >
                <table className={"JobPostingTable"} >
                    <tbody>
                    <tr>
                        <td colSpan={2}>
                            <div className={"JobPosting-Title"}>
                                {job.title}
                            </div>
                            <div className={"JobPosting-Employer"}>
                                {job.employer}
                            </div>
                        </td>
                        <td colSpan={2} className={"JobPosting-Location"}>
                            <div>
                                {job.location.city}, {job.location.state}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <b>jobKey</b>
                        </td>
                        <td>
                            {shortenText (job.jobKey)}
                        </td>
                        <td>
                            <b>onetCode</b>
                        </td>
                        <td>
                            {job.onetCode}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>educationType</b>
                        </td>
                        <td>
                            {job.educationType}
                        </td>
                        <td>
                            <b>employmentType</b>
                        </td>
                        <td>
                            {job.employmentType}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <br/>
                            <b>
                                Description
                                &nbsp;
                                <i
                                    className="far fa-chevron-double-down"
                                    style={{ color: "steelblue" }}
                                    onClick={() => this.store.expanded = ! this.store.expanded}
                                />
                            </b>
                            {this.store.expanded
                                ? (
                                    <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                )
                                : (
                                    <div/>
                                )
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className={"JobPosting-When"} colSpan={4}>
                            {this.renderWhen (job)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderWhen (job) {
        const m = moment (new Date (job.posted));
        return (
            <i>
                Posted on {m.format('MMMM Do YYYY, h:mm:ss a')} ({m.fromNow ()})
            </i>
        );
    }
}

export default observer (JobPosting);

// EOF