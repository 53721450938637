
class JobPostingUtil {
    findJobsGql =  `query ($req: JobPostingFindRequest!) { 
            findJobPostings (req: $req) { 
                total 
                skip
                limit            
                results {                
                    id
                    jobKey
                    title
                    description
                    employer 
                    location { city state } 
                }
            }
        }`;
}

export default new JobPostingUtil ();

// EOF